<script>
// eslint-disable-next-line no-unused-vars
import TablePage from '../../../../../components/table_page';
// eslint-disable-next-line no-unused-vars
import request from '../../../../../utils/request';

import * as utils from '../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/sfa/sfaSignFormsController/workOvertimeSummary',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @desc 折叠搜索条件 */
    foldSearchList(n) {
      n.map((v, k) => {
        const rowData = v;
        if (k > 3) {
          rowData.folding = true;
        }
        return rowData;
      });
      return n;
    },
  },
  created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    this.getConfigList('sfa_attendance_weekday_adjust_summary').then(() => {
      this.foldSearchList(this.searchList);
    });
  },
};
</script>
